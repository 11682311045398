<template>
  <div class="intro-container" :class="{ 'mobile-bg': isMobile, 'desktop-bg': !isMobile }">
    <div class="intro-content">
      <h1>사용자 홈</h1>
      <p>여기는 사용자 홈 페이지입니다.</p>
      <p v-if="isMobile"> 
         현재 디스플레이는<br>
         모바일 화면입니다.
      </p>
      <p v-else> 현재 디스플레이는 데스크탑 화면입니다.</p>
      <!-- <p>현재 화면 폭: {{ windowWidth }}px</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserHome',
  data() {
    return {
      isMobile: false,
      windowWidth: 0,
    };
  },
  mounted() {
    this.checkViewport();
    window.addEventListener('resize', this.checkViewport);
  },
  methods: {
    checkViewport() {
      this.isMobile = window.innerWidth <= 768;
      this.windowWidth = window.innerWidth;
      console.log('isMobile:'+this.isMobile);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkViewport);
  }
}
</script>

<style scoped>
/* 기본 스타일 */
.intro-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  padding: 50px;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
}

/* 모바일 배경 */
.mobile-bg {
  background-image: url('../../assets/images/drone22.jpg');
  background-size: cover;
  height: auto;
}

/* 데스크탑 배경 */
.desktop-bg {
  background-image: url('../../assets/images/drone22.jpg');
  background-size: cover;
}

/* 콘텐츠 스타일 */
.intro-content {
  text-align: center;
  color: white;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  max-width: 90%;
  box-sizing: border-box;
}

/* 제목과 본문 스타일 */
h1 {
  font-size: 2rem;
}

p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .intro-content {
    font-size: 1rem;
    padding: 15px;
    max-width: 100%;
  }

  h1 {
    font-size: 1.5rem;
  }
}
</style>
